import classNames from 'classnames'
import Image from 'next/image'
import { GTMEvent, logEvent } from 'utils/GTM'

export const STAKING_LINKS = {
    cosmos: {
        slug: 'cosmos',
        name: 'cosmos-hub',
        chain: 'cosmoshub-4',
    },
    osmosis: {
        slug: 'osmosis',
        name: 'osmosis',
        chain: 'osmosis-1',
    },
    'injective-protocol': {
        slug: 'injective-protocol',
        name: 'injective',
        chain: 'injective-1',
    },
    // cronos
    'crypto-com-coin': {
        slug: 'crypto-com-coin',
        name: 'cronos-pos',
        chain: 'crypto-org-chain-mainnet-1',
    },
    akash: {
        slug: 'akash',
        name: 'akash',
        chain: 'akashnet-2',
    },
    solana: {
        link: 'https://solanabeach.io/validator/{address}',
        logoUrl:
            'https://pbs.twimg.com/profile_images/1374797279438200840/OJgMtfGd_200x200.jpg',
    },
    cardano: {
        link: 'https://cardanoscan.io/pool/{address}',
        logoUrl: 'https://cardanoscan.io/public/assets/images/logo-sm.png',
    },
    'binance-smart-chain': {
        link: 'https://www.bnbchain.org/en/staking/validator/{address}',
        logoUrl:
            'https://altcoinsbox.com/wp-content/uploads/2023/01/bnb-chain-binance-smart-chain-logo-300x300.webp',
    },
    sui: {
        link: 'https://suiscan.xyz/mainnet/validator/{address}/info?stake=true',
        logoUrl:
            'https://ens.suiscan.xyz/uploads/posts/2023-01/thumbs/suiscan.png',
    },
    // near wallet is discontinued, just keeping this here for reference
    // 'near-protocol': {
    //     link: 'https://wallet.near.org/staking/{address}/stake',
    // },
    'injective-protocol': {
        slug: 'injective-protocol',
        name: 'injective',
        chain: 'injective-1',
    },
    mina: {
        link: 'https://minascan.io/mainnet/validator/{address}/delegations',
        logoUrl:
            'https://strapi-dev.scand.app/uploads/Minascan_Pool_Staketab_6f0f639ccd.jpeg',
    },
    tezos: {
        link: 'https://tzstats.com/{address}',
        logoUrl:
            'https://pbs.twimg.com/profile_images/1292754365460164608/4XT7kZT6_400x400.jpg',
    },
    'casper-network': {
        link: 'https://cspr.live/validator/{address}',
        logoUrl: 'https://cspr.live/assets/icons/logos/cspr-live-full.svg',
    },
    fuse: {
        link: 'https://console.fuse.io/stake/{address}',
        logoUrl:
            'https://media.licdn.com/dms/image/C4D0BAQE5cl1kEEiR2g/company-logo_200_200/0/1675695265218/fuseio_logo?e=2147483647&v=beta&t=mH7xwoW7phX03F7WmqfF4Ae5COsmxEbdPqTUY5pWsdE',
    },
    klever: {
        link: 'https://kleverscan.org/validator/{address}',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2023/05/6724.png',
    },
    evmos: {
        slug: 'evmos',
        name: 'evmos',
        chain: 'evmos_9001-2',
    },
    irisnet: {
        slug: 'irisnet',
        name: 'irisnet',
        chain: 'irishub-1',
    },
    juno: {
        slug: 'juno',
        name: 'juno',
        chain: 'juno-1',
    },
    kava: {
        slug: 'kava',
        name: 'kava',
        chain: 'kava_2222-10',
    },
    persistence: {
        slug: 'persistence',
        name: 'persistence',
        chain: 'core-1',
    },
    regen: {
        slug: 'regen',
        name: 'regen',
        chain: 'regen-1',
    },
    secret: {
        slug: 'secret',
        name: 'secret-network',
        chain: 'secret-4',
    },
    sentinel: {
        slug: 'sentinel',
        name: 'sentinel',
        chain: 'sentinelhub-2',
    },
    sifchain: {
        slug: 'sifchain',
        name: 'sifchain',
        chain: 'sifchain-1',
    },
    stargaze: {
        slug: 'stargaze',
        name: 'stargaze',
        chain: 'stargaze-1',
    },
    umee: {
        slug: 'umee',
        name: 'umee',
        chain: 'umee-1',
    },
    aptos: {
        link: 'https://explorer.aptoslabs.com/validator/{address}?network=mainnet',
        logoUrl:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCorjcXQ_8YckSeOArxpe79to9KExOf5E43aW81I5oLg&s',
    },
    dydx: {
        slug: 'dydx',
        name: 'dydx',
        chain: 'dydx-mainnet-1',
    },
    agoric: {
        slug: 'agoric',
        name: 'agoric',
        chain: 'agoric-3',
    },
    archway: {
        link: 'https://connect.archway.io/staking',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2022/12/1-2.jpg',
    },
    'aura-network': {
        link: 'https://aurascan.io/validators/{address}',
        logoUrl:
            'https://assets.coingecko.com/coins/images/25768/large/LOGO-AURA-WHITE.png?1696524855',
    },
    axelar: {
        slug: 'axelar',
        name: 'axelar',
        chain: 'axelar-dojo-1',
    },
    celestia: {
        slug: 'celestia',
        name: 'celestia',
        chain: 'celestia',
    },
    'injective-protocol': {
        link: 'https://hub.injective.network/validators/{address}/',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2021/10/Primary-Symbol-e1634044136552.jpg',
    },
    cronos: {
        slug: 'cronos',
        name: 'cronos-pos',
        chain: 'crypto-org-chain-mainnet',
    },
    fantom: {
        link: 'https://wallet.fantom.network/staking',
        logoUrl:
            'https://wallet.fantom.network/static/media/wallet_logo_powered_by.e8d4b3eb.svg',
    },
    '1inch': {
        link: 'https://app.1inch.io/#/1/dao/delegate/{address}',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2021/02/1inch_token.png',
    },
    'origin-protocol': {
        link: 'https://app.story.xyz/stake',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2020/12/Origin-protocol.jpg',
    },
    ontology: {
        link: 'https://node.ont.io/app/node-stake',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2021/02/Profile-1-e1612198010324.jpg',
    },
    'aleph-zero': {
        link: 'https://azero.dev/#/staking',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2022/10/g1glpjytshbssozyyy9z.png',
    },
    paraswap: {
        link: 'https://app.paraswap.io/#/earn/hybrid?network=ethereum',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2021/11/paraswap-e1636998068516.jpeg',
    },
    chromia: {
        link: 'https://staking.chromia.com/',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2020/03/chromia.png',
    },
    cartesi: {
        link: 'https://explorer.cartesi.io/stake/{address}',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2021/02/Cartesi-Logo.png',
    },
    radix: {
        link: 'https://dashboard.radixdlt.com/network-staking/{address}',
        logoUrl:
            'https://cms.stakingrewards.com/wp-content/uploads/2022/03/radix-e1647427407173.webp',
    },
    dymension: {
        slug: 'dymension',
        name: 'dymension',
        chain: 'dymension_1100-1',
    },
    kyve: {
        slug: 'kyve-network',
        name: 'kyve',
        chain: 'kyve-1',
    },
    passage: {
        slug: 'passage',
        name: 'passage',
        chain: 'passage-2',
    },
    sommelier: {
        slug: 'sommelier',
        name: 'sommelier',
        chain: 'sommelier-3',
    },
    stride: {
        slug: 'stride',
        name: 'stride',
        chain: 'stride-1',
    },
    'terra-luna-2': {
        slug: 'terra-luna-2',
        name: 'terra',
        chain: 'phoenix-1',
    },
    terra: {
        slug: 'terra',
        name: 'terra-classic',
        chain: 'columbus-5',
    },
    'islamic-coin': {
        link: 'https://shell.haqq.network/staking/validator/{address}',
        logoUrl:
            'https://assets.coingecko.com/coins/images/32284/large/islamic-mark.png',
    },
    cudos: {
        link: 'https://dashboard.cudos.org/staking/{address}',
        logoUrl:
            'https://assets.coingecko.com/coins/images/13651/large/CudosIconTransparent.png',
    },
    zetachain: {
        link: 'https://hub.zetachain.com/staking/{address}',
        logoUrl:
            'https://assets.coingecko.com/coins/images/26718/large/Twitter_icon.png',
    },
    polkadot: {
        link: 'https://web.subwallet.app/earning-preview?chain=polkadot&type=NATIVE_STAKING&target={address}',
        logoUrl: 'https://web.subwallet.app/images/subwallet/gradient-logo.png',
    },
    kusama: {
        link: 'https://web.subwallet.app/earning-preview?chain=kusama&type=NATIVE_STAKING&target={address}',
        logoUrl: 'https://web.subwallet.app/images/subwallet/gradient-logo.png',
    },
    moonbeam: {
        link: 'https://web.subwallet.app/earning-preview?chain=moonbeam&type=NATIVE_STAKING&target={address}',
        logoUrl: 'https://web.subwallet.app/images/subwallet/gradient-logo.png',
    },
    'vara-network': {
        link: 'https://web.subwallet.app/earning-preview?chain=vara_network&type=NATIVE_STAKING&target={address}',
        logoUrl: 'https://web.subwallet.app/images/subwallet/gradient-logo.png',
    },
    'partisia-blockchain': {
        link: 'https://browser.partisiablockchain.com/assets',
        logoUrl:
            'https://assets.coingecko.com/coins/images/24898/large/Group_15910.png?1710864310&w=640&q=75',
    },
    'fuse-network-token': {
        link: 'https://console.fuse.io/stake/{address}',
        logoUrl:
            'https://assets.coingecko.com/coins/images/10347/large/fuse.png',
    },
    stacks: {
        link: 'https://lockstacks.com/choose-stacking-method?chain=mainnet',
        logoUrl:
            'https://storage.googleapis.com/stakingrewards-static/images/assets/production/stacks_logo.png?v=1711536485535&w=640&q=75',
    },
}

export function StakeWithButton({
    item,
    profileItem,
    validator,
    isNewDesign,
    className,
    disabled,
}) {
    const { name, chain, link, logoUrl } =
        STAKING_LINKS[profileItem?.slug] || {}
    if (!name && !link) {
        return null
    }
    const isKeplr = name && chain && !link
    const regexp = new RegExp('https?://(?:www.)?([^/.]+)', 'i')
    const stakeWith = isKeplr ? 'Keplr' : regexp.exec(link)[1]
    const size = isNewDesign ? '14' : '24'
    const sizes = 'min-w-[14px] min-h-[14px] min-w-[24px] min-h-[24px]' // for tailwind to not prune the classes

    return (
        <button
            className={classNames(
                'text-xs font-bold rounded-[4px] bg-contrast-6 border-0 text-contrast-0 flex flex-nowrap whitespace-nowrap items-center gap-1 px-8 py-3 justify-center',
                {
                    'text-xs h-8 min-w-24 hover:bg-shade-darkest/90 transition-all duration-200 ease-in-out !px-4':
                        isNewDesign,
                    '!cursor-not-allowed opacity-50': disabled,
                },
                className
            )}
            onClick={e => {
                if (disabled) {
                    return
                }
                e.preventDefault()
                e.stopPropagation()
                if (link) {
                    window.open(
                        link.replace('{address}', validator?.address),
                        '_blank'
                    )
                } else {
                    window.open(
                        `https://wallet.keplr.app/chains/${name}?modal=validator&chain=${chain}&validator_address=${validator?.address}`,
                        '_blank'
                    )
                }
                logEvent(GTMEvent.VspStakeButton, {
                    provider: item?.providers?.[0]?.slug,
                    asset: profileItem?.slug,
                    page_path: window.location.pathname

                })
            }}
            disabled={disabled}
        >
            <span
                className={
                    isNewDesign
                        ? 'text-transparent bg-clip-text bg-gradient-to-bl from-cyan/20 via-magenta/20 to-yellow-sr/20 bg-contrast-1'
                        : ''
                }
            >
                Stake with{' '}
            </span>
            {isKeplr ? (
                <Image
                    src='/static/img/keplr-logo.png'
                    alt='Keplr'
                    width={size}
                    height={size}
                    className={`rounded-full overflow-hidden min-w-[${size}px] min-h-[${size}px]`}
                />
            ) : logoUrl ? (
                <Image
                    src={logoUrl}
                    alt={name ?? 'Logo'}
                    width={size}
                    height={size}
                    className={`rounded-full overflow-hidden min-w-[${size}px] min-h-[${size}px]`}
                />
            ) : (
                stakeWith
            )}
        </button>
    )
}
